.crossroads {
  position: absolute;
  transform: rotate(-45deg);
  z-index: 0;
  border: 1px solid black;
  border-top-right-radius: 15px 225px;
  border-top-left-radius: 5px 255px;
  border-bottom-right-radius: 225px 15px;
  border-bottom-left-radius: 15px 255px;
  background-color: white;
  width: 50px;
  height: 50px;
}

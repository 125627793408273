.page {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #eee;
  min-width: 100%;
  height: 100%;
}

.sidebar,
.content {
  margin: 1.5rem;
  border: 1px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  padding: 1.5rem;
}

.sidebar {
  flex: 1;
}

.sidebar div {
  cursor: pointer;
  padding: 0.5rem 0;
  font-weight: 400;
}

.sidebar div:hover {
  font-weight: 800;
}

.sidebar div.active {
  font-weight: 800;
}

.content {
  flex: 5;
  margin-right: 200px;
}

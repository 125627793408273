.TextEditor {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid #333;
  border-radius: 2px;
  background-color: white;
  width: min(40rem, 80vw);
}

.TextEditor .toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 2px solid #ccc;
  padding: 10px 5px;
}

.TextEditor .toolbar .button {
  cursor: pointer;
  color: #ccc;
}

.TextEditor .toolbar .active {
  cursor: pointer;
  color: #333;
}

.TextEditor .editable {
  flex: 1;
  padding: 0 10px;
  overflow: scroll;
}

.TextEditor img {
  display: block;
  margin-bottom: 0.5em;
  max-width: 100%;
  max-height: 20em;
}

.TextEditor .deleteImage {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  cursor: pointer;
  border-radius: 2px;
  background-color: white;
  padding: 10px;
}

.page {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #eee;
  width: 100%;
  height: 100%;
}

.content {
  margin-top: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  padding: 1.5rem;
  width: min(650px, 80vw);
}

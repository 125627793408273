.GraphEditor {
  position: "relative";
  flex: 1;
}

.tools {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  z-index: 10;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #eee;
  padding: 10px;
}

.tools button {
  margin: 5px 10px;
  font-size: 0.8rem;
}

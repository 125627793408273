/* Book animations by omer certin: https://codepen.io/omercetin/pen/abOKPjo */

.page {
  display: flex;
  flex-direction: column;
  background-color: #eee;
  width: 100%;
  min-height: 100%;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin: 1.5rem 1.5rem 0 1.5rem;
}

.sort {
  padding: 0.5em;
  padding-right: 2em;
}

.library {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  padding: 0px 50px;
}

.card {
  margin: 50px 10px;
}

.book {
  position: relative;
  margin: 2.5%;
  width: 150px;
  height: 225px;
  text-align: center;
}

.bookCover {
  position: absolute;
  transform-origin: 0 50%;
  -webkit-transform-origin: 0 50%;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  cursor: pointer;
  box-shadow: inset 4px 1px 3px #ffffff60, inset 0 -1px 2px #00000080;
  border-radius: 3px;
  background: #eee;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.book .bookCover {
  background-size: 100% 100%;
}

.effect {
  position: absolute;
  transition: all 0.5s ease;
  margin-left: 10px;
  border-left: 2px solid #00000010;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 20px;
  height: 100%;
}

.titleContainer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
}

.title {
  padding: 10px;
  color: black;
  font-family: "Playfair Display", serif;
}

.image {
  border: 1px solid #ccc;
  background-size: cover;
  background-color: white;
  width: 100px;
  height: 100px;
}

.date {
  opacity: 0;
  background-color: white;
  font-size: 0.8rem;
  font-family: "Playfair Display", serif;
  text-align: center;
}

.card:hover .date {
  opacity: 1;
}

.light {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.1;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  border-radius: 3px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  width: 90%;
  height: 100%;
}

.book:hover .bookCover {
  transform: perspective(2000px) rotateY(-30deg);
  -webkit-transform: perspective(2000px) rotateY(-30deg);
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  box-shadow: inset 4px 1px 3px #ffffff60, inset 0 -1px 2px #00000080,
    10px 0px 10px -5px #00000030;
}

.book:hover .effect {
  width: 40px;
  /** margin-left:13px;
  opacity: 0.5; **/
}

.book:hover .light {
  opacity: 1;
  width: 70%;
}

.bookInside {
  position: relative;
  top: 2%;
  box-shadow: 10px 40px 40px -10px #00000030, inset -2px 0 0 grey,
    inset -3px 0 0 #dbdbdb, inset -4px 0 0 white, inset -5px 0 0 #dbdbdb,
    inset -6px 0 0 white, inset -7px 0 0 #dbdbdb, inset -8px 0 0 white,
    inset -9px 0 0 #dbdbdb;
  border: 1px solid grey;
  border-radius: 3px;
  background: white;
  width: calc(100% - 2px);
  height: 96%;
}

.book:hover .btn,
.book:hover {
  opacity: 1;
}

.tabContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tabContent img {
  align-self: center;
  max-width: 100%;
}

.tabContent p {
  margin: 0.5em 0;
}

.tabContent h1,
.tabContent h2 {
  margin: 1em 0 0 0;
}

.nodeStartImg {
  max-height: 150px;
}

.nodeImg {
  max-height: 100px;
}

.page {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  background-color: #eee;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  padding: 50px;
}

.profilePic {
  width: 5em;
  height: 5rem;
}

.fields {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.field {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: baseline;
  margin: 0.2rem 0;
}

.field > label {
  width: 6rem;
}

.saveBtn {
  margin-bottom: 20px;
  width: 12rem;
}

.signOutBtn {
  border-color: red;
  width: 12rem;
  color: red;
}
